import { AppsManagementResponse, CMSResponse } from '../../types/common.type';
import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import {
    Career,
    CareerPage,
    RecruitData,
    TitleInCareer,
} from '@shared/data/recruit.data';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';
import { DatePipe } from '@angular/common';
import { IAppCompany, ICompaniesRecruit } from '../../types/recruit.type';
@Injectable({
    providedIn: 'root',
})
export class RecruitService extends RecruitData {
    private HttpClient = inject(HttpClient);

    constructor() {
        super();
    }
    toDay = new DatePipe('en-US').transform(new Date(), 'YYYY-MM-dd');
    GetCareerPage({
        limit = '',
        offset = '',
        specializes = [],
        companies = [],
    }: {
        limit?: string | number;
        offset?: string | number;
        specializes?: any;
        companies?: any;
    }): Observable<AppsManagementResponse<CareerPage[]>> {
        let url = `${environment.clientUrl}/careers`;
        return this.HttpClient.get<AppsManagementResponse<any[]>>(url, {
            params: {
                limit: limit,
                offset: offset,
                specializes: specializes,
                companies: companies,
            },
        });
    }
    GetDetailCareer(slug: string): Observable<CMSResponse<CareerPage>> {
        let url = `${environment.clientUrl}/careers/${slug}`;
        return this.HttpClient.get<CMSResponse<CareerPage>>(url);
    }
    override GetCompanies(): Observable<CMSResponse<ICompaniesRecruit[]>> {
        let url = `${environment.clientUrl}/companies`;
        return this.HttpClient.get<CMSResponse<ICompaniesRecruit[]>>(url);
    }
    override GetAppCompanies(
        slug: string
    ): Observable<CMSResponse<IAppCompany[]>> {
        let url = `${environment.clientUrl}/company/${slug}/apps`;
        return this.HttpClient.get<CMSResponse<IAppCompany[]>>(url);
    }
}
